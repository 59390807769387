<template>
  <section>
    <button
      type="button"
      class="btn btn-green"
      :disabled="disabled"
      @click="create"
    >
      {{ $t('woodWing.create_article') }}
    </button>
  </section>
</template>

<script>
import { dateTimeNow } from '@/services/DateTimeService'
import NotifyService from '../../services/NotifyService'

const ARTICLE_DEFAULT_SITE = 1

export default {
  name: 'WoodWingCreateArticleButton',
  props: {
    woodWing: {
      type: Object
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  data () {
    return {
      disabled: false,
      showModal: false
    }
  },
  methods: {
    toggleModal () {
      this.showModal = !this.showModal
    },
    getDefaultSiteId () {
      if (this.currentUser.defaultSite) {
        return this.currentUser.defaultSite
      }
      if (this.currentUser.sites.length > 0) {
        return this.currentUser.sites[0]
      }
      return ARTICLE_DEFAULT_SITE
    },
    async getDefaultRubricId () {
      if (this.currentUser.defaultRubric) {
        return this.currentUser.defaultRubric
      }
      const defaultSiteId = this.getDefaultSiteId()
      // this is fetching only rubrics that are not fetched yet
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', defaultSiteId)
      const rubrics = this.$store.getters['rubric/all']
      const rubric = rubrics.find(rubric => rubric.site === defaultSiteId)
      return rubric.id
    },
    makeTitleValid (title) {
      return title.length < 3 ? '' : title
    },
    async getArticleModel () {
      return {
        site: this.getDefaultSiteId(),
        mainRubric: await this.getDefaultRubricId(),
        field: {
          ...this.woodWing.field,
          title: this.makeTitleValid(this.woodWing.field.title),
          shortTitle: this.makeTitleValid(this.woodWing.field.shortTitle)
        },
        gallery: this.woodWing.gallery,
        setting: {
          copiedFromPrint: true
        },
        sharedUsers: [this.woodWing.sharedUser].filter((i) => i),
        expanded: {
          sharedUsers: [this.woodWing.sharedUser].filter((i) => i).map((i) => ({ id: i })),
          gallery: this.woodWing.expanded.gallery
        },
        publishedSince: dateTimeNow(),
        orderDate: dateTimeNow()
      }
    },
    async create () {
      this.disabled = true
      this.$store.dispatch('article/create', await this.getArticleModel())
        .then(() => {
          if (this.$store.getters['article/error']) {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
            this.disabled = false
            return
          }
          const newArticle = this.$store.getters['article/fullArticle']
          NotifyService.setSuccessMessage(this.$t('recipe.article_created'))
          if (newArticle.id > 0) {
            this.$emit('set-article-and-save', newArticle)
            this.$router.push('/article/' + newArticle.id + '/edit')
          }
        })
        .catch(error => {
          console.error(error)
          this.disabled = false
        })
    }
  }
}
</script>
