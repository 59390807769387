<template>
    <app-module-view>
        <template slot="body">
            <section class="m-b-10">
                <div class="row">
                    <div class="col-12 text-right m-r-5">
                        <app-wood-wing-create-article-button
                            v-if="transferButtonVisible"
                            :woodWing="woodWing"
                            @set-article-and-save="setArticleAndSave"
                        ></app-wood-wing-create-article-button>
                    </div>
                </div>
            </section>
            <section>
                <div class="card card-body">
                    <h3 class="article-edit-header">{{ $t("article.main_content") }}</h3>
                    <table class="table table-striped table-bordered">
                        <tbody>
                            <tr v-if="woodWing.expanded.site">
                              <th>{{ $t("article.site") }}</th>
                              <td>{{ woodWing.expanded.site.title }}</td>
                            </tr>
                            <tr v-if="woodWing.expanded.issue">
                                <th>{{ $t('woodWing.issue') }}</th>
                                <td>{{ woodWing.expanded.issue.title }}</td>
                            </tr>
                            <tr>
                                <th width="200">{{ $t('article.title') }}</th>
                                <td>{{ woodWing.field.title }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('article.short_title') }}</th>
                                <td>{{ woodWing.field.shortTitle }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('article.sub_title') }}</th>
                                <td>{{ woodWing.field.subTitle }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('article.first_paragraph') }}</th>
                                <td>{{ woodWing.field.firstParagraph }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('article.body_text') }}</th>
                                <td>{{ woodWing.field.bodyText }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t("article.gallery") }}</th>
                                <td>
                                    <div v-for="image in woodWing.expanded.gallery" :key="image.id">
                                        <app-media
                                            :media="image"
                                            :width="480"
                                            :height="270"
                                            show-media-info
                                        >
                                        </app-media>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card card-body">
                    <h3 class="article-edit-header">{{ $t('topicOffer.transferred_articles') }}</h3>
                    <table class="table table-striped table-bordered">
                        <tbody>
                        <tr v-for="article in articles" :key="article.id">
                            <td>
                                <router-link
                                    tag="a"
                                    :to="{ name: 'article_detail', params: { id: article.id }}"
                                >
                                    {{ article.field.title }}
                                </router-link>
                            </td>
                            <td>
                                <app-article-status :article="article"></app-article-status>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </template>
    </app-module-view>
</template>

<script>
import appArticleStatus from '../article/ArticleStatus'
import appMedia from '../../components/shared/Media'
import appModuleView from '../../components/ModuleView'
import appWoodWingCreateArticleButton from './WoodWingCreateArticleButton'
import CoreApi from '../../api/core'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'WoodWing',
  data () {
    return {
      articles: [],
      transferButtonVisible: false
    }
  },
  components: {
    appArticleStatus,
    appMedia,
    appModuleView,
    appWoodWingCreateArticleButton
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    woodWing () {
      return this.$store.getters['woodWing/detail']
    }
  },
  methods: {
    async getWoodWing () {
      await this.$store.dispatch('woodWing/fetchOne', this.$route.params.id)
      this.fetchArticles()
    },
    async fetchArticles () {
      const articleDocumentIds = this.woodWing.articles.map((article) => article.articleDocumentId)
      if (articleDocumentIds.length > 0) {
        try {
          const response = await CoreApi().get('/article?view=minimal&filter_in[documentId]=' + articleDocumentIds)
          this.articles = response.data.data
        } catch (error) {
          console.error(error)
        }
      }
    },
    setArticleAndSave (article) {
      this.woodWing.articles.push({ articleDocumentId: article.documentId })
      this.$store.dispatch('woodWing/update', this.woodWing)
    },
    async checkTransferButtonVisibility () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_WOOD_WING_PERMISSIONS.transferArticleButton
      const woodWing = await this.woodWing

      if (woodWing && woodWing.site) {
        this.transferButtonVisible = this.$store.getters['user/hasPermissionForSite'](permission, woodWing.site)
        return
      }
      this.transferButtonVisible = false
    }
  },
  async beforeMount () {
    await this.getWoodWing()
    await this.checkTransferButtonVisibility()
  }
}
</script>

<style scoped lang="scss">
  .article-edit-header {
    color:#bbb;
    font-weight: 100;
  }
</style>
